<div class="jumbotron">
  <div class="d-flex justify-content-center">
<!--    <img src="/assets/mojaloop-foundation-orange-300x95.png"/>-->
    <img style="width: 600px;" src="/assets/mojaloop_logo.png"/>
  </div>

  <div class="row align-items-center" style="margin-bottom: 25px"></div>

  <div class="d-flex justify-content-center">
    <h1 class="display-4">Welcome!</h1>
  </div>
  <div class="d-flex justify-content-center">
    <p class="lead">This is Mojaloop's Future Administrative User Interface</p>

  </div>
	<div class="d-flex justify-content-center">
		<p>v {{getVersion()}} - {{getEnvName()}}</p>
	</div>
  <hr class="my-4 row align-items-center">
  <div class="d-flex justify-content-center">
    <p> In this web application, you will be able to manage all aspects of Mojaloop's vNext Platform</p>
  </div>

  <ng-container *ngIf="!(isLoggedIn | async)">
    <div class="d-flex justify-content-center">
      <p> Please login to see more</p>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary" routerLink="/login">Login</button>
    </div>
  </ng-container>



</div>


<div style="text-align: center; font-size: 1.1rem;" class="mb-2">
	<a style="color: black" href="https://mojaloop.io/mojaloop/" aria-label="Link to https://mojaloop.io" target="_self" rel="noopener">
		https://mojaloop.io
	</a>
</div>
<div class="container" style="text-align: center; font-size: 1.1rem;">

	<a style="color: black" class="mr-2" href="https://github.com/mojaloop/" aria-label="Link to https://github.com/mojaloop/" target="_self" rel="noopener">
		<i class="bi bi-github"></i>
	</a>
	<a style="color: black" class="mr-2 lr-2" href="https://community.mojaloop.io/" aria-label="Link to https://community.mojaloop.io/" target="_self" rel="noopener">
		<i class="bi bi-chat-fill"></i>
	</a>
	<a style="color: black" class="mr-2 lr-2" href="https://twitter.com/mojaloop" aria-label="Link to https://twitter.com/mojaloop" target="_self" rel="noopener">
		<i class="bi bi-twitter"></i>
	</a>
	<a style="color: black" class="mr-2 lr-2" href="https://www.linkedin.com/company/mojaloop" aria-label="Link to https://www.linkedin.com/company/mojaloop" target="_self" rel="noopener">
		<i class="bi bi-linkedin"></i>
	</a>
	<a style="color: black" class="mr-2 lr-0" href="https://www.facebook.com/Mojaloop" aria-label="Link to https://www.facebook.com/Mojaloop" target="_self" rel="noopener">
		<i class="bi bi-facebook"></i>
	</a>
	<a style="color: black" class="mr-2 lr-2" href="https://www.youtube.com/channel/UCvSh2G3MpsLlZoSDBq14I1Q" aria-label="Link to https://www.youtube.com/channel/UCvSh2G3MpsLlZoSDBq14I1Q" target="_self" rel="noopener">
		<i class="bi bi-youtube"></i>
	</a>
</div>
