<div *ngIf="(transfer | async) as item;">


	<div class=" row"> <!--	Top header-->
		<div class="col-md-8 align-self-center">
			<h2>Transfer Details</h2>
		</div>

		<div class="col-md-4 align-self-center">
			<small><strong>ID: </strong> {{item.transferId}}
				<a (click)="copyTransferIdToClipboard()"
				   ngbTooltip="Copy ID to clipboard"><i class="bi bi-clipboard-plus"></i></a></small>
		</div>
	</div> <!--	Top header-->
	<div class=" row">
		<div class="col-md-12 align-self-center text-secondary" *ngIf="(payer | async) && (payee | async)">
			<h5>{{item.amount}} ({{item.currencyCode}}) - {{(payer | async)?.name}} <i class="bi bi-arrow-right"></i> {{(payee | async)?.name}} </h5>
		</div>
	</div>

	<hr>

	<div class="row">

		<div class="col-4"> <!-- col 1 -->

			<div class="mb-2">
				<label class="col-form-label text-secondary">Payer FSP</label>
				<div><a href="/participants/{{item.payerFspId}}">{{(payer | async)?.name}}</a></div>
			</div>

			<div class="mb-2">
				<label class="col-form-label text-secondary">Payee FSP</label>
				<div><a href="/participants/{{item.payeeFspId}}">{{(payee | async)?.name}}</a></div>
			</div>

			<div class="mb-2">
				<label class="col-form-label text-secondary">Last update</label>
					<div class="text-break">{{item.updatedAt | momentDate}}</div>
			</div>

			<div class="mb-2">
				<label class="col-form-label text-secondary">Fulfilment Date</label>
				<div class="text-break" *ngIf="!item?.completedTimestamp">-</div>
				<div class="text-break" *ngIf="item?.completedTimestamp">{{item.completedTimestamp | momentDate}}</div>
			</div>


		</div> <!-- col 1 -->

		<div class="col-5"> <!-- col 2 -->
			<div class="mb-2">
				<label class="col-form-label text-secondary">Status</label>
				<div
					[ngClass]="item.transferState==='COMMITTED'?'text-success':'text-danger'">{{item.transferState}}</div>
			</div>

			<div class="mb-2">
				<label class="col-form-label text-secondary">Amount</label>
				<div class="text-break">{{ formatCommaSeparator(item.amount) }} ({{item.currencyCode}})</div>
			</div>

			<div class="mb-2">
				<label class="col-form-label text-secondary">Condition</label>
				<div class="text-break">{{item.fspiopOpaqueState.condition}}</div>
			</div>

			<!--<div class="mb-2">
				<label class="col-form-label text-secondary">Settled</label>
				<div class="text-break" *ngIf="!(settlementTransfer | async)?.settled">No</div>
				<div class="text-break" *ngIf="(settlementTransfer | async)?.settled">Yes&lt;!&ndash; - <a routerLink="/settlements/matrix/{{(settlementTransfer | async)?.matrixId}}">Matrix</a>&ndash;&gt;</div>
			</div>-->


		</div> <!-- col 2 -->

		<div class="col-3"> <!-- column 3 - buttons -->

			<a href="/settlements/transfers?transferId={{item.transferId}}" class="btn btn-primary btn-block" [hidden]="item.transferState !=='COMMITTED'">
 				Search in Settlements
			</a>

			<a href="/quotes/byTransactionId/{{item.transferId}}" class="btn btn-primary btn-block" [hidden]="item.transferId == null || item.transferId == undefined">
				Quote Info
			</a>

		</div>  <!-- column 3 - buttons -->
	</div>
</div>

<ul ngbNav #nav="ngbNav" class="mt-4 nav-tabs" (navChange)="tabChange($event)">
	<li ngbNavItem="fsp"> <!-- fsp -->
		<a ngbNavLink>Parties</a>
		<ng-template ngbNavContent>
			<h4 class="mt-3">List of parties involved in the transfer</h4>
			<table class="table table-hover mt-3">
				<thead class="thead-light">
					<tr>
						<th scope="col">FSP Type</th>
						<th scope="col">Party Type</th>
						<th scope="col">Party Identifier</th>
						<th scope="col">Party SubType</th>
						<th scope="col">fspId</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Payer</td>
						<td>{{decodedIlpPacket?.payer?.partyIdInfo?.partyIdType}}</td>
						<td>{{decodedIlpPacket?.payer?.partyIdInfo?.partyIdentifier}}</td>
						<td>{{decodedIlpPacket?.payer?.partyIdInfo?.partySubIdOrType}}</td>
						<td>{{decodedIlpPacket?.payer?.partyIdInfo?.fspId}}</td>
					</tr>
					<tr>
						<td>Payee</td>
						<td>{{decodedIlpPacket?.payee?.partyIdInfo?.partyIdType}}</td>
						<td>{{decodedIlpPacket?.payee?.partyIdInfo?.partyIdentifier}}</td>
						<td>{{decodedIlpPacket?.payee?.partyIdInfo?.partySubIdOrType}}</td>
						<td>{{decodedIlpPacket?.payee?.partyIdInfo?.fspId}}</td>
					</tr>
				</tbody>
			</table>
		</ng-template>
	</li><!-- fsp -->
	<li ngbNavItem="ilp"> <!-- interledger -->
		<a ngbNavLink>ILP</a>
		<ng-template ngbNavContent>
			<h4 class="mt-3">Interledger protocol</h4>


			<div class="row">
				<div class="col-12">
					<div class="mb-2">
						<label class="col-form-label text-secondary">Condition</label>
						<div style="font-family: monospace;">{{(transfer | async)?.fspiopOpaqueState.condition}}</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<label class="col-form-label text-secondary">ILP Packet</label>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<pre><p style="font-family: monospace;">{{((transfer | async)?.fspiopOpaqueState.ilpPacket | json)}}</p></pre>
				</div>
			</div>


		</ng-template>
	</li><!-- interledger -->
</ul>

<div class="mt-6" [ngbNavOutlet]="nav"></div>
